
export default {
    state: {
        cartItems: JSON.parse(window.localStorage.getItem('cart') ?? "[]"),
        customer: {
            lastName: window.localStorage.getItem('lastName') ?? "",
            email: window.localStorage.getItem('email') ?? "",
            phone: window.localStorage.getItem('phone') ?? "",
            address: window.localStorage.getItem('address') ?? "",
            address2: window.localStorage.getItem('address2') ?? "",
            city: window.localStorage.getItem('city') ?? "",
            zip_code: window.localStorage.getItem('zip_code') ?? ""
        },
        orderType: window.localStorage.getItem('orderType') ?? null,
        paymentMethod: window.localStorage.getItem('paymentMethod') ?? null,
        scheduled: null,
        CACAdresse: null,
    },



    mutations: {
        UPDATE_SCHEDULED_TIME(state: any, payload: any) {
            state.scheduled = payload;
        },
        UPDATE_CART_ITEMS(state: any, payload: any) {
            state.cartItems = payload;
            window.localStorage.setItem("cart", JSON.stringify(state.cartItems));
        },
        ADD_ITEM_TO_CART(state: any, product: any) {
            state.cartItems.push(product);
            window.localStorage.setItem("cart", JSON.stringify(state.cartItems));
        },
        CHECKOUT(state: any) {
            state.cartItems = [];
        },
        UPDATE_CUSTOMER_INFO(state: any, cutomer: any) {
            state.customer.email = cutomer.email;
            state.customer.lastName = cutomer.lastName;
            state.customer.phone = cutomer.phone;
            state.customer.address = cutomer.address;
            state.customer.address2 = cutomer.address2;
            state.customer.city = cutomer.city;
            state.customer.zip_code = cutomer.zip_code;
            window.localStorage.setItem("email", cutomer.email);
            window.localStorage.setItem("lastName", cutomer.lastName);
            window.localStorage.setItem("phone", cutomer.phone);
            window.localStorage.setItem("address", cutomer.address);
            window.localStorage.setItem("address2", cutomer.address2);
            window.localStorage.setItem("city", cutomer.city);
            window.localStorage.setItem("zip_code", cutomer.zip_code);
        },
        REMOVE_ONE_CART_ITEMS(state: any, payload: any) {
            state.cartItems.splice(payload, 1);
            window.localStorage.setItem("cart", JSON.stringify(state.cartItems));
        },
        UPDATE_ORDER_TYPE(state: any, payload: any) {
            state.orderType = payload;
            window.localStorage.setItem("orderType", payload);
        },
        UPDATE_PAYMENT_METHOD(state: any, payload: any) {
            state.paymentMethod = payload;
            window.localStorage.setItem("paymentMethod", payload);
        },
        UPDATE_CAC_ADDRESSE(state: any, payload: any) {
            state.CACAdresse = payload;
        },
    },
    actions: {
        getCartItems({ commit }: any) {
            commit('UPDATE_CART_ITEMS', [])
        },
        addCartItem({ commit }: any, cartItem: any) {
            commit('ADD_ITEM_TO_CART', cartItem);
        },
        removeCartItem({ commit }: any, index: number) {
            commit('REMOVE_ONE_CART_ITEMS', index)
        },
        removeAllCartItems({ commit }: any) {
            commit('UPDATE_CART_ITEMS', [])
        },
        setCutomerInfo({ commit }: any, customer: any) {
            commit('UPDATE_CUSTOMER_INFO', customer)
        },
        postOrder({ commit }: any) {
            commit('UPDATE_CART_ITEMS', [])
        },
        updateOrderType({ commit }: any, orderType: string) {
            commit('UPDATE_ORDER_TYPE', orderType)
        },
        updatePaymentMethod({ commit }: any, paymentMethod: string) {
            commit('UPDATE_PAYMENT_METHOD', paymentMethod)
        },
        updateScheduledMethod({ commit }: any, scheduledTime: string) {
            commit('UPDATE_SCHEDULED_TIME', scheduledTime)
        },
        updateCAC({ commit }: any, cac: number) {
            commit('UPDATE_CAC_ADDRESSE', cac)
        },


    },

    getters: {
        ordredProducts: (state: any) => state.cartItems,
        customerInfo: (state: any) => state.customer,
        orderType: (state: any) => state.orderType,
        paymentMethod: (state: any) => state.paymentMethod,
        getscheduledTime: (state: any) => state.scheduled,
        getcacAdresse: (state: any) => state.CACAdresse,

    }

}
